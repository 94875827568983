/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import QueryString from 'qs';

/**
 * getAdtype - returns adtype from url param
 * @param {*} adType
 * @return {string} string
 */
const getAdtype = (adType) => {
    if (typeof adType === 'object') {
        return adType[0];
    }
    return adType;
};

/**
 * isProductListingAd - Check URL paramaters to see if we're on a PLA page
 * @param {string} locationSearch - url Parameters (location.search)
 * @return {bool} bool
 */
const isProductListingAd = (locationSearch) => {
    const params = QueryString.parse((locationSearch || '').replace('?', ''));
    const adtype = getAdtype(params.adtype);
    if (
        adtype === 'pla' || params?.ref?.includes('pla') || params?.addisttype?.includes('pla') || params?.adtype?.includes('pla')
    ) {
        return true;
    }
    return false;
};

export default isProductListingAd;
